/* main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681f2;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex: 1;
}

a {
  font-size: 12px;
  color: black;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  display: block;
}

a:hover {
  text-decoration: none;
  color: gray;
}

a:visited {
  text-decoration: none;
  color: black;
}

a > i {
  margin-right: 10px;
  padding: 8px;
  color: white;
  background-color: brown;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  width: 30px;
  height: 30px;
}



.happiness {
  font-size: 2vw;
  margin-top: 40px;
}

.email_site {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-around;
  bottom: 2em;
}

.souls_club {
  font-size: 2em;
  font-weight: bold;
  text-transform: uppercase;
}

.connecting {
  font-weight: 600;
  font-size: 2em;

}

.first-div {
  align-items: center;
  position: relative;
  flex: 1;
  border: 2px solid brown;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.second-div {
  flex: 1;
  display: flex;
  aspect-ratio: 1/1;
  object-fit: contain;
  margin-left: 16px;
}



body {
  display: flex;
  flex: 1;
  padding: 0;
  margin: 0;
}

.app {
  padding: 16px;
  display: flex;
  flex: 1;
}

.loader-gif {
  margin-left: -30px;
  height: 100px !important;
}

.immmm {
  padding: 121px;
}

@media screen and (max-width:768px) {
  .second-div {
    display: none;
  }

  .happiness {
    font-size: 16px;
  }

  .souls_club {
    font-size: 20px;
  }

  .connecting {
    font-size: 14px;
  }

  .email_site {
    gap: 10px;
    flex-direction: column;
  }
}

@media screen and (min-width:992px) {
  .second-div {
    display: none;
  }
}

@media screen and (min-width:1200px) {
  .second-div {
    display: flex;
  }
}